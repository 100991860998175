import { useLatestPublishedBulletin } from "./getLatestPublishedBulletin";
import { buildUpcomingSeries } from "../../components/Bulletin/buildUpcomingSeries";
import { usePublicContext } from "../PublicContext";
import { formatISO } from "date-fns";

export const WhatsOnUpcomingSeries = () => {
  const { sermons, series } = usePublicContext();
  const latestPublishedBulletin = useLatestPublishedBulletin();

  if (!sermons || !latestPublishedBulletin) return null;

  const { title, content } = buildUpcomingSeries(
    latestPublishedBulletin,
    formatISO(new Date(), { representation: "date" }),
    series ?? [],
    sermons ?? []
  );

  if (!title) {
    return <></>;
  }

  return (
    <div id="upcoming-series">
      <div style={{ fontWeight: "bold" }}>{title}</div>
      {content}
    </div>
  );
};
