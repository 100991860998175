import "./SimilarPosts.scss";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { getPathPrefix } from "../../../utils";
import { SQLNullTime } from "../../Interfaces/Interfaces";

export interface SimilarPostItem {
  published_on: SQLNullTime;
  slug: string;
  title: string;
}

export const NUM_SIMILAR_ITEMS = 7; // should be odd, to have the selected item in the middle
const MOBILE_NUM_SIMILAR_ITEMS = 5;
const MOBILE_BREAKPOINT = 500;

const num_items = () => {
  if (
    document.body.clientWidth <= MOBILE_BREAKPOINT &&
    document.body.clientWidth > 0
  )
    return MOBILE_NUM_SIMILAR_ITEMS;
  return NUM_SIMILAR_ITEMS;
};

export const SimilarPosts = (props: {
  title: string;
  collection: SimilarPostItem[];
  currentIndex: number;
}) => {
  const adjustedCurrentIndex = useRef(0);
  const [collection, setCollection] = useState<SimilarPostItem[]>([]);

  useEffect(() => {
    const buffer = Math.floor(num_items() / 2);

    const minIndex = Math.max(
      Math.min(
        props.currentIndex - buffer,
        props.collection.length - num_items()
      ),
      0
    );
    const maxIndex = minIndex + num_items();

    adjustedCurrentIndex.current = props.currentIndex - minIndex;

    const publishedItems = props.collection.filter(item => {
      const {Time, Valid} = item.published_on;
      return Valid && new Date(Time) <= new Date();
    });

    setCollection(publishedItems.slice(minIndex, maxIndex));
  }, [props.collection, props.currentIndex]);

  return (
    <div className="similar-posts">
      <h3>{props.title}</h3>
      <TransitionGroup className="similar-posts-items">
        {collection.map((item, itemIndex) => (
          <CSSTransition key={item.slug} timeout={200} classNames="my-node">
            <SimilarItem
              item={item}
              isCurrentItem={itemIndex === adjustedCurrentIndex.current}
              currentIndex={props.currentIndex}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
};

const SimilarItem = (props: {
  item: SimilarPostItem;
  currentIndex: number;
  isCurrentItem: boolean;
}) => {
  if (props.isCurrentItem)
    return (
      <div
        className="similar-post-item similar-post-active"
        data-testid="similar-item"
      >
        {props.item.title}
      </div>
    );

  const basePath = getPathPrefix(window.location.pathname);
  const path = `${basePath}/${props.item.slug}`;

  return (
    <Link
      to={path}
      className="similar-post-item similar-post-link"
      data-testid="similar-item"
    >
      {props.item.title}
    </Link>
  );
};
