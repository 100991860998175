import {
  Bulletin,
  SermonInterface,
  SpeakerSeriesData,
} from "../Interfaces/Interfaces";
import parse from "html-react-parser";
import { format } from "date-fns";
import { ReactElement } from "react";

export const buildUpcomingSeries = (
  bulletin: Bulletin,
  currentTime: string,
  series: SpeakerSeriesData[],
  sermons: SermonInterface[]
): { title: string | null; content: ReactElement; imageLink: string | null} => {
  const upcomingSeries = series.find((x) => x.id === bulletin.sermon_series_id);

  if (!upcomingSeries) {
    return {
      title: null,
      content: <></>,
      imageLink: null,
    };
  }

  const seriesSermons = sermons.filter(
    (x) => x.series.id === bulletin.sermon_series_id
  );

  const upcomingSermons = seriesSermons
    .filter((x) => x.published_on.Valid && x.sermon_date.Time > currentTime)
    .reverse();

  const title = `${
    upcomingSermons.length === seriesSermons.length
      ? `Upcoming Series: `
      : `Ongoing Series: `
  }${upcomingSeries?.name}`;


  return {
    title,
    content: (
      <>
        {parse(upcomingSeries?.content ?? "", { trim: true })}
        <UpcomingSeriesTable sermons={upcomingSermons} />
      </>
    ),
    imageLink: upcomingSeries?.image_link.String ?? "",
  };
};

const UpcomingSeriesTable = (props: { sermons: SermonInterface[] }) => {
  if (props.sermons.length === 0) return <></>;

  const showPassage =
    props.sermons.filter((x) => x.main_passage && x.main_passage !== "")
      .length > 0;

  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Title</th>
          {showPassage && <th>Main Passage</th>}
          <th>Speaker</th>
        </tr>
      </thead>
      <tbody>
        {props.sermons.map((x) => (
          <tr key={x.id}>
            <td style={{ whiteSpace: "nowrap" }}>
              {format(new Date(x.sermon_date.Time), "d MMM")}
            </td>
            <td>{x.title}</td>
            {showPassage && <td>{x.main_passage ? x.main_passage : "-"}</td>}
            <td>{x.speaker.map((x) => x.name).join(" & ")}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
